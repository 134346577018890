<template>
  <div class="detail">
    <p class="detail__name">#{{ index }}</p>
    <div class="detail__info">
      <div class="detail__info__content">
        <p class="detail__info__content__text">【問題】<br>{{ question.question }}</p>
        <p class="detail__info__content__text">【解説】<br>{{ question.description }}</p>
      </div>
      <div class="detail__info__answer">
        <p class="detail__info__answer__text">Đáp án đúng<br><span class="detail__info__answer__text__big">{{ correctAnswer }}</span></p>
        <p class="detail__info__answer__text">Câu trả lời của bạn<br><span class="detail__info__answer__text__big">{{ yourAnswer }}</span></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    // 試験問題情報
    question: {
      type: Object,
      required: true
    },
    // 試験解答情報
    answer: {
      type: Object,
      required: true
    },
    // 問題番号
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    /**
     * @return {String} 正しい解答
     */
    correctAnswer () {
      return this.question.correct ? 'あっています' : '間違っています'
    },
    /**
     * @return {String} ユーザーの解答
     */
    yourAnswer () {
      return this.answer.answer ? 'あっています' : '間違っています'
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.detail {
  padding: 24px 10px;
  background-color: #ffffff;
  border-radius: 5px;
  text-align: left;
  &__name {
    margin: 0;
    font-size: 20px;
  }
  &__info {
    margin-top: 5px;
    font-size: 12px;
    display: flex;
    align-items: center;
    &__content {
      flex: 1;
      min-width: 0;
      &__text {
        margin: 0;
      }
    }
    &__answer {
      width: 115px;
      margin-left: 25px;
      text-align: center;
      &__text {
        margin: 0;
        padding: 5px;
        font-size: 10px;
        line-height: 14px;
        background-color: #fffadf;
        &:first-of-type {
          margin-bottom: 12px;
        }
        &__big {
          font-size: 15px;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
