<template>
  <div class="processing">
    <img class="processing__img" src="@/assets/img/processing.gif">
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.processing {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
  z-index: 100;
  &__img {
    display: block;
    height: auto;
    width: 40vw;
    margin: auto;
    margin-top: calc((100vh - 40vw)/2);
  }
}
</style>
