<template>
  <div class="nav">
    <div class="nav__box--retry" @click="$router.push({ name: 'ExamQuestion', params: { eid: eid }})">
      <img src="@/assets/img/reload.svg" />
      <p class="nav__box__text">Thử lại</p>
    </div>
    <div class="nav__box--main" @click="$router.push({ name: 'Exam' })">
      <img src="@/assets/img/home-white.svg" />
      <p class="nav__box__text">Trang chính</p>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    // 試験情報ID
    eid: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.nav {
  position: fixed;
  bottom: 0;
  left : 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 83px;
  color: white;
  &__box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    height: 100%;
    &--retry {
      @extend .nav__box;
      background-color: #ff9d44;
    }
    &--main {
      @extend .nav__box;
      background-color: #a3a3a3;
    }
    &__text {
      margin: 0;
      font-size: 20px;
      line-height: 27px;
    }
  }
}
</style>
