<template>
  <div class="exam-result">
    <div v-if="!isProcessing">
      <div class="exam-result__result">
        <p class="exam-result__result__title">Kết quả</p>
        <p class="exam-result__result__score">{{ score + '/100' }}</p>
      </div>
      <h1 class="exam-result__title">Giải thích</h1>
      <template v-if="answerOrderedQIDs.length === 0">
        <exam-result-detail class="exam-result__detail" v-for="(question, qid, index) in questions" :question=question :answer=answer[qid] :index="index + 1"  :key=qid />
      </template>
      <template v-else>
        <exam-result-detail class="exam-result__detail" v-for="(qid, index) in answerOrderedQIDs" :question=questions[qid] :answer=answer[qid] :index="index + 1"  :key=qid />
      </template>
      <exam-result-nav :eid=eid />
    </div>
    <!-- 処理中 -->
    <processing v-if="isProcessing" />
  </div>
</template>

<script>
import ExamResultDetail from '@/components/exam/result/detail'
import ExamResultNav from '@/components/exam/result/nav'
import Processing from '@/components/common/processing'

export default {
  name: 'exam-result',
  components: { ExamResultDetail, ExamResultNav, Processing },
  data () {
    return {
      // 処理中かどうか
      isProcessing: true
    }
  },
  /** 初回アクセス時の取得処理
   * データがstoreに格納されていなければ下記のフローで取得する
   * (1) 試験ID(eid)に紐づく問題情報(questions)を取得
   * (2) 試験問題ID(qid)に紐づく解答情報(answers)を取得
   */
  async mounted () {
    // 問題数
    const QUESTION_AMOUNT = 20

    // (1) 試験ID(eid)に紐づく問題情報(questions)を取得
    const promises = []
    // 試験問題情報
    if (!this.$store.getters['questions/questions'](this.eid)) promises.push(this.$store.dispatch('questions/getQuestions', this.eid))
    if (promises.length > 0) await Promise.all(promises)

    // 試験問題が20問ではない場合は404へ遷移
    if (!this.$store.getters['questions/questions'](this.eid) ||
    Object.keys(this.$store.getters['questions/questions'](this.eid)).length !== QUESTION_AMOUNT) {
      this.$router.push({ name: 'NotFound' })
      return 0
    }

    // (2) 試験問題ID(qid)に紐づく解答情報(answers)を取得
    const answersPromises = []
    const qids = Object.keys(this.$store.getters['questions/questions'](this.eid))
    // 試験問題情報をチェック
    qids.filter(qid => {
      const payload = { eid: this.eid, qid: qid, uid: this.uid }
      // 試験IDごとに問題を持っているかチェック、storeに無ければ取得する
      if (!this.$store.getters['answers/answer']({ eid: this.eid, qid: qid })) answersPromises.push(this.$store.dispatch('answers/getAnswer', payload))
    })
    if (answersPromises.length > 0) await Promise.all(answersPromises)

    // 初回アクセス時の処理終了
    this.isProcessing = false
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['user/uid']
    },
    /**
     * @return {String} 試験情報ID
     */
    eid () {
      return this.$route.params.eid
    },
    /**
     * @return {Object} 試験IDに紐付く削除済みでない試験問題情報一覧
     */
    questions () {
      return this.$store.getters['questions/questions'](this.eid)
    },
    /**
     * @return {Object} 試験解答情報
     */
    answer () {
      return this.$store.getters['answers/answers'](this.eid)
    },
    /**
     * @return {String[]} 回答順に並んだ試験問題のID配列
     */
    answerOrderedQIDs () {
      return this.$store.getters['questions/answerOrderedQIDs']
    },
    /**
     * 試験の採点
     * @return {Number} 試験の点数
     */
    score () {
      // 正答数
      const correctAnswer = Object.keys(this.answer).reduce((score, qid) => {
        return this.answer[qid].answer === this.questions[qid].correct ? score + 1 : score
      }, 0)
      // 1問あたりの点数
      const points = 100 / Object.keys(this.questions).length
      // 点数 = 正答数 * 1問あたりの点数
      return Math.round(correctAnswer * points)
    }
  },
  methods: {
    /**
     * studyページに戻る
     */
    back () {
      this.$router.push({ name: 'Study' })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.exam-result {
  background-color: #f6f6f6;
  text-align: center;
  padding: 30px $main_padding 100px;
  &__result{
    color: white;
    &__title {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      height: 70px;
      font-size: 20px;
      background-color: #ff9d44;
      border-radius: 5px 5px 0 0;
    }
    &__score {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      font-size: 40px;
      background-color: #ffa758;
      border-radius: 0 0 5px 5px;
    }
  }
  &__title {
    margin-top: 27px;
    text-align: left;
    font-size: 20px;
    line-height: 27px;
  }
  &__detail {
    margin-top: 10px;
    &:first-of-type {
      margin-top: 14px;
    }
  }
}
</style>
